export default () => {
  return (
    <section
      className="flex bg-cover bg-center bg-no-repeat text-white text-center"
      style={{
        backgroundImage: 'url(/images/warehouse.jpg)',
      }}
    >
      <div className="flex relative absolute top-0 flex-1">
        <div
          className="flex flex-1"
          style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
        >
          <div className="container mx-auto px-10 py-40 flex flex-col flex-1 justify-center items-center">
            <h2 className="text-[30px] leading-[35px] md:text-[52px] mb-10">
              Facture in Numbers
            </h2>
            <p className="text-[20px] leading-[28px] md:text-2xl font-normal max-w-xl mb-10">
              Gain access to the world's most diverse and comprehensive art
              database
            </p>
            <div className="xl:flex text-center">
              <div className="flex-1 mx-5">
                <h3
                  className="text-[30px] leading-[35px] md:text-[42px] font-bold border-b py-4 my-4 md:py-8 md:my-8"
                  style={{ borderColor: 'rgba(255,255,255,0.2)' }}
                >
                  675,000+
                </h3>
                <p className="text-[14px] md:text-lg">Artists</p>
              </div>
              <div className="flex-1 mx-5">
                <h3
                  className="text-[30px] leading-[35px] md:text-[42px] font-bold border-b py-4 my-4 md:py-8 md:my-8"
                  style={{ borderColor: 'rgba(255,255,255,0.2)' }}
                >
                  13 million+
                </h3>
                <p className="text-[14px] md:text-lg">
                  Auction records from 100,000 auction houses going back half a
                  century
                </p>
              </div>
              <div className="flex-1 mx-5">
                <h3
                  className="text-[30px] leading-[35px] md:text-[42px] font-bold border-b py-4 my-4 md:py-8 md:my-8"
                  style={{ borderColor: 'rgba(255,255,255,0.2)' }}
                >
                  35,000+
                </h3>
                <p className="text-[14px] md:text-lg">
                  Private and public art collections
                </p>
              </div>
              <div className="flex-1 mx-5">
                <h3
                  className="text-[30px] leading-[35px] md:text-[42px] font-bold border-b py-4 my-4 md:py-8 md:my-8"
                  style={{ borderColor: 'rgba(255,255,255,0.2)' }}
                >
                  2 million+
                </h3>
                <p className="text-[14px] md:text-lg">
                  Artworks from Museums worldwide
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
