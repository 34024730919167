import type { NextPage } from 'next';
import Head from 'next/head';
import { useState } from 'react';

import BusinessSection from '../components/BusinessSection';
import EditingSection from '../components/EditingSection';
import FeaturedSection from '../components/FeaturedSection';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import ImageRecognitionSection from '../components/ImageRecognitionSection';
import Menu from '../components/Menu';
import NumbersSection from '../components/NumbersSection';
import SearchSection from '../components/SearchSection';
import StayInTouchSection from '../components/StayInTouchSection';
import TimelineSection from '../components/TimelineSection';
import TrustedSection from '../components/TrustedSection';
import WhatsappSection from '../components/WhatsappSection';

const Index: NextPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <Head>
        <title>Facture: Catalogues Raisonnés as a Subscription</title>
      </Head>

      <main>
        <HeroSection onMenuOpen={() => setMenuOpen(!menuOpen)} />
        <TrustedSection />
        <TimelineSection />
        <NumbersSection />
        <SearchSection />
        <FeaturedSection />
        <ImageRecognitionSection />
        <EditingSection />
        <StayInTouchSection />
        <WhatsappSection />
        <BusinessSection />
        <Footer />
        <Menu onMenuOpen={() => setMenuOpen(!menuOpen)} open={menuOpen} />
      </main>
    </>
  );
};

export default Index;
