import Image from 'next/image';
import { assetPath } from '../constants';

export default () => {
  return (
    <section
      className="bg-cover bg-center bg-no-repeat overflow-hidden"
      id="artwork-timeline"
      style={{ backgroundColor: '#f3f3f3' }}
    >
      <div className="container mx-auto px-10 py-20">
        <h2 className="font-medium uppercase text-[15px] leading-[23px] md:text-[18px] md:leading-[26px] lg:text-[20px] lg:leading-[31px] mb-6">
          1. Artwork Timeline
        </h2>
        <div className="md:flex md:space-x-10 max-md:space-y-10">
          <div className="flex-1">
            <h3 className="font-normal text-[27px] leading-[31px] md:text-[30px] md:leading-[35px] lg:text-[40px] lg:leading-[46px]">
              Catalogue Raisonné, auction prices, exhibition history:
              <br />
              All in one place with the artwork timeline.
            </h3>
          </div>
          <div className="flex-1 text-lg leading-[30px]">
            <p className="mb-6">
              Starting in 2016 over the course of five years we meticulously
              digitised and reorganised hundreds of catalogues raisonnés,
              important monographs and exhibition catalogues of the world's most
              visible artists to bring what is generally referred to as a "price
              database" into the 21st Century.
            </p>
            <p>
              Welcome to the future of online, aggregated and always in itself
              updating catalogues raisonnés.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white flex flex-col justify-center items-center">
        <Image
          src="/images/timeline-search.svg"
          alt=""
          width="1443"
          height="62"
          className="my-8 ml-80 md:ml-40 min-w-[150%] md:min-w-full"
        />
        <Image
          src="/images/timeline-card.svg"
          alt=""
          width="414"
          height="170"
          className="p-10"
        />
        <div style={{ top: 2, position: 'relative' }}>
          <video autoPlay playsInline loop muted>
            <source src={`${assetPath}/video/timeline.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
};
