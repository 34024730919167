import Image from 'next/image';

export default () => {
  return (
    <section
      className="bg-cover bg-center bg-no-repeat overflow-hidden text-white text-center"
      style={{
        backgroundColor: '#3d3d3f',
      }}
    >
      <div className="container mx-auto px-10 py-20 max-w-xl">
        <h2 className="text-4xl font-normal mb-6">
          Or why don't you try it out?
        </h2>
        <div className="md:flex md:space-x-10 max-md:space-y-10">
          <div className="flex-1 text-lg leading-[30px]">
            <p className="mb-6">
              Try out our state-of-the-art image recognition technology on
              WhatsApp, free of charge for a week!
            </p>
            <p className="mb-6">
              Simply{' '}
              <a
                href="https://wa.me/447787067215"
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                follow this link
              </a>{' '}
              and get started.
            </p>
          </div>
        </div>
        <div>
          <a href="https://wa.me/447787067215" target="_blank" rel="noreferrer">
            <Image
              src="/images/whatsapp.png"
              alt="Facture Whatsapp"
              width="46"
              height="46"
              className="m-auto"
            />
          </a>
        </div>
      </div>
    </section>
  );
};
