// import Image from 'next/image';
import 'swiper/css';

export default () => {
  const images = [
    {
      path: '/images/vanham-logo.png',
      title: 'Van Ham',
      url: 'https://www.van-ham.com/',
    },
    {
      path: '/images/gagosian-logo.png',
      title: 'Gagosian',
      url: 'https://gagosian.com/',
    },
    {
      path: '/images/va-logo.png',
      title: 'V&A',
      url: 'https://www.vam.ac.uk/',
    },
    {
      path: '/images/koller-logo.svg',
      title: 'Koller',
      url: 'https://www.kollerauktionen.ch/',
    },
    {
      path: '/images/artsy-logo.png',
      title: 'Artsy',
      url: 'https://www.artsy.net/',
    },
    {
      path: '/images/casa-daste-capitolium-art-logo.png',
      title: 'Capitolium Art',
      url: 'https://www.capitoliumart.it/en',
    },
    {
      path: '/images/blomqvist-logo.png',
      title: 'Blomqvist',
      url: 'https://www.blomqvist.no/',
    },
    {
      path: '/images/heritage-logo.png',
      title: 'Heritage Auctions',
      url: 'https://www.ha.com/',
    },
    {
      path: '/images/trinity-logo.png',
      title: 'Trinity House Paintings',
      url: 'https://www.trinityhousepaintings.com/',
    },
    {
      path: '/images/hauser-and-wirth-logo.png',
      title: 'Hauser & Wirth',
      url: 'https://www.hauserwirth.com/',
    },
    {
      path: '/images/phillips-logo.png',
      title: 'Phillips',
      url: 'https://www.phillips.com/',
    },
    {
      path: '/images/xavier-hufkens-logo.png',
      title: 'Xavier Hufkens',
      url: 'https://www.xavierhufkens.com/',
    },
    {
      path: '/images/colnaghi-logo.png',
      title: 'Colnaghi',
      url: 'https://www.colnaghi.com/',
    },
    {
      path: '/images/bassenge-logo.png',
      title: 'Bassenge',
      url: 'https://bassenge.com/',
    },
    {
      path: '/images/dickinson-logo.png',
      title: 'Simon Dickinson',
      url: 'https://www.simondickinson.com/',
    },
    {
      path: '/images/hollis-logo.png',
      title: 'Hollis Uaggart',
      url: 'https://www.hollistaggart.com/',
    },
    {
      path: '/images/gagosian-art-advisory-logo.png',
      title: 'Gagosian Art Advisory',
      url: 'https://gagosianartadvisory.com/',
    },
    {
      path: '/images/benuri-logo.png',
      title: 'Benuri',
      url: 'https://benuri.org/',
    },
    {
      path: '/images/hindman-logo.png',
      title: 'Hindman Auctions',
      url: 'https://hindmanauctions.com/',
    },
    {
      path: '/images/mg-logo.png',
      title: 'The Matthews Gallery',
      url: 'https://www.thematthewsgallery.com/',
    },
  ];
  const imageStyles = {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'block',
    height: 120,
    width: 220,
  };
  return (
    <section>
      <div className="container mx-auto px-10 pt-12">
        <p className="text-center text-base font-normal uppercase pb-6">
          Trusted by
        </p>
      </div>

      <div className="relative overflow-x-hidden pb-12">
        <div className="hover-pause">
          <div
            className="flex animate-marquee whitespace-nowrap"
            style={{ width: images.length * 220 }}
          >
            {images.map((image) => (
              <a
                key={image.path}
                href={image.url}
                title={image.title}
                target="_blank"
                rel="noreferrer"
                className="hover:scale-125 transition-transform duration-300"
                style={{
                  backgroundImage: `url(${image.path})`,
                  ...imageStyles,
                }}
              />
            ))}
          </div>
          <div
            className="flex absolute top-0 animate-marquee2 whitespace-nowrap"
            style={{ width: images.length * 220 }}
          >
            {images.map((image) => (
              <a
                key={image.path}
                href={image.url}
                title={image.title}
                target="_blank"
                rel="noreferrer"
                className="hover:scale-125 transition-transform duration-300"
                style={{
                  backgroundImage: `url(${image.path})`,
                  ...imageStyles,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
