import Image from 'next/image';

export default () => {
  const row1 = [
    {
      image: '/images/search-image-christies.png',
      source: '/images/search-logo-christies.png',
      title:
        'Eduardo Chillida (1924-2002)\nElogio de la arquitectura IV, Executed in 1974',
      description:
        "Alabaster\n15 × 17 × 16cm\n\nSold at Chrstie's, London\nLive Auction 1106\nPost-War and Contemporary Art (Evening Auction)\n13 February 2013",
    },
    {
      image: '/images/search-image-artsy.png',
      source: '/images/search-logo-artsy.png',
      title: 'Vanessa Beecroft\nMiki Alabaster, 2012',
      description:
        'White alabaster marble\n195 × 34.5 × 48 cm\n\nCourtesy of Lia Rumma\nMilan /Napoli',
    },
    {
      image: '/images/search-image-wallace.png',
      source: '/images/search-logo-wallace.png',
      title: 'Unknown Artist / Maker\nThe Resurrection, 15th century',
      description:
        'Alabaster, gilded and painted\n44.5 × 27.9 cm\n\nThe Wallace Collection, London',
    },
    {
      image: '/images/search-image-phillips.png',
      source: '/images/search-logo-phillips.png',
      title: 'Sterling Ruby\nAlabaster Centrifugal #1, 2007',
      description:
        "Acrylic, in artist's frame\n124.8 × 124.8 cm\n\nSold at Phillips, New York\n20th Century & Contemporary Art Day Sale,\n16 May 2018",
    },
  ];
  const row2 = [
    {
      image: '/images/search-image-kunst.png',
      source: '/images/search-logo-kunst.png',
      title: 'Gesichtsstele\nKopf 2. - 1. Jh. v. Chr.',
      description:
        'Alabaster\nH 20,5 cm, B 10,5 cm, T 6 cm\n\nKunsthistorisches Museum, Wien',
    },
    {
      image: '/images/search-image-ketterer.png',
      source: '/images/search-logo-ketterer.png',
      title: 'Louise Bourgeois\nCunt l/ Untitled (Femme), 1969/70',
      description:
        'Rosa Alabaster\n17 × 7 × 5 cm\n\nSold at Ketterer Kunst, Munich\nSale: 437 / Post War |\n10 December 2016',
    },
    {
      image: '/images/search-image-met.png',
      source: '/images/search-logo-met.png',
      title: 'Alabaster alabastron\n(perfume vase), 5th-4th century B.C.',
      description:
        'Gypsum (alabaster)\n13.3 × 4.4 cm\n\nThe Metropolitan Museum of Art,\nNew York',
    },
    {
      image: '/images/search-image-catrais.png',
      source: '/images/search-logo-catrais.png',
      title:
        'Alberto Giacometti\nPair of "Le Poing" Sconces\ndesigned circa 1935',
      description:
        'gilt bronze, alabaster\n28.89 × 14.29 × 22.86 cm\n\nCat. No: AGD 4157\n\nPrivate Collection',
    },
    {
      image: '/images/search-image-artnet.png',
      source: '/images/search-logo-artnet.png',
      title: 'Rick Owens\nHalf Box, 2011',
      description:
        'Half Box (Alabaster), 2011\nSculpture\n77 × 50 × 50 cm\nLimited Edition of 8 + 4 AP\n\nCourtesy of Carpenters\nWorkshop Gallery\nParis, New York, London, San Francisco',
    },
  ];
  return (
    <section
      className="bg-cover bg-center bg-no-repeat overflow-hidden"
      id="search"
      style={{
        backgroundColor: '#fff52c',
        height: 1600,
      }}
    >
      <div className="container mx-auto px-10 py-20">
        <h2 className="font-medium uppercase text-[15px] leading-[23px] md:text-[18px] md:leading-[26px] lg:text-[20px] lg:leading-[31px] mb-6">
          2. Search
        </h2>
        <div className="md:flex md:space-x-20 max-md:space-y-10">
          <div className="flex-1">
            <h3 className="font-normal text-[27px] leading-[31px] md:text-[30px] md:leading-[35px] lg:text-[40px] lg:leading-[46px]">
              Elevate your curatorial practice onto another level.
            </h3>
          </div>
          <div className="flex-1 text-lg leading-[30px]">
            <p>
              Deepen your knowledge and capabilities with an agnostic search
              functionality that goes beyond an artist's name or an auction
              house. Search entire private collections, past important
              exhibitions or a specific material for an overview of art historic
              exceptionalism that is second to none.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mx-5 md:mx-10">
        <Image
          src="/images/search-input.svg"
          alt=""
          width="683"
          height="123"
          className="my-10"
        />
        <p className="text-xl text-center font-normal uppercase my-5">
          Search results:
        </p>
        <div className="container md:px-10">
          <div className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-10 gap-10 md:gap-20">
            {row1.map((card, i) => (
              <div
                className={`my-4 md:my-10 md:col-span-2 lg:col-span-3 ${
                  [1, 2].some((n) => n === i) && 'lg:col-span-2'
                } ${i === 3 && 'sm:block md:hidden lg:block'}`}
                key={card.title}
              >
                <Image
                  src={card.image}
                  height="100"
                  width="100"
                  alt={card.title}
                  style={{
                    width: '100%',
                    maxHeight: 250,
                  }}
                />
                <div
                  className="bg-contain bg-left bg-no-repeat my-4 md:my-6"
                  style={{
                    backgroundImage: `url(${card.source})`,
                    height: 30,
                    width: 100,
                  }}
                />
                <p className="text-xs mb-4 whitespace-pre-wrap">{card.title}</p>
                <p
                  className="text-xs whitespace-pre-wrap"
                  style={{ opacity: 0.6 }}
                >
                  {card.description}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-10 gap-10">
            {row2.map((card) => (
              <div className="my-10 md:col-span-2" key={card.title}>
                <Image
                  src={card.image}
                  height="100"
                  width="100"
                  alt={card.title}
                  style={{
                    width: '100%',
                    maxHeight: 250,
                  }}
                />
                <div
                  className="bg-contain bg-left bg-no-repeat my-4 md:my-6"
                  style={{
                    backgroundImage: `url(${card.source})`,
                    height: 30,
                    width: 120,
                  }}
                />
                <p className="text-xs mb-4 whitespace-pre-wrap">{card.title}</p>
                <p
                  className="text-xs whitespace-pre-wrap"
                  style={{ opacity: 0.6 }}
                >
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
