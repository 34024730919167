import Link from 'next/link';
import SignupForm from './SignupForm';

export default () => {
  return (
    <section
      className="bg-cover bg-center bg-no-repeat overflow-hidden text-center"
      id="signup"
      style={{ backgroundColor: '#fff52c' }}
    >
      <div className="container mx-auto px-10 py-20 max-w-xl">
        <h2 className="text-4xl font-medium mb-6">Stay in touch</h2>
        <div className="md:flex md:space-x-10 max-md:space-y-10">
          <div className="flex-1 text-lg leading-[30px]">
            <p>
              Sign up for our newsletter and be the first to know of new
              features, artists and pricing options on Facture.
            </p>
          </div>
        </div>
        <SignupForm />
      </div>
    </section>
  );
};
