import 'swiper/css';

export default () => {
  const images = [
    {
      path: '/images/art-news-logo.jpg',
      title: 'Art News',
      url: 'https://www.artnews.com/art-news/sponsored-content/facture-debuts-1234596476/',
    },
    {
      path: '/images/handelsblatt-logo.svg',
      title: 'Handelsblatt',
      url: 'https://www.handelsblatt.com/arts_und_style/kunstmarkt/online-only-eine-neue-app-vereinfacht-das-geschaeft-mit-kunstauktionen-im-internet/25764510.html?ticket=ST-93141-sfmAq4XEtu3i9VDaGFWp-ap4',
    },
    {
      path: '/images/faz-logo.png',
      title: 'Faz',
      url: 'https://www.faz.net/aktuell/wirtschaft/unternehmen/christian-huhnt-der-kunst-nerd-18739626.html?fbclid=PAAabRdcR4etLnyXe2K9bEDn-lI55JfUMPGHvX5Wi-OiRJS4oOk184WipJVSk_aem_th_Ae7VIxngRlrbcEv49tLPxlwyRb9pnAFu1tw3dHe2pSsE01MVjTs1jXpP3OFVhC5xOhs',
    },
    {
      path: '/images/art-tactic-logo.png',
      title: 'Art Tactic',
      url: 'https://arttactic.com/podcast/christian-huhnt-glass/',
    },
  ];
  const imageStyles = {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'block',
    height: 100,
    width: 200,
  };
  return (
    <section>
      <div className="container mx-auto px-10 pt-12">
        <p className="text-center text-base font-normal uppercase pb-6">
          Featured on
        </p>
      </div>

      <div className="relative overflow-x-hidden pb-12">
        <div className="hover-pause">
          <div className="md:flex justify-center whitespace-nowrap">
            {images.map((image) => (
              <a
                key={image.path}
                href={image.url}
                title={image.title}
                target="_blank"
                rel="noreferrer"
                className="mx-auto md:mx-10 hover:scale-125 transition-transform duration-300"
                style={{
                  backgroundImage: `url(${image.path})`,
                  ...imageStyles,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
