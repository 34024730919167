import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Link from 'next/link';

export default () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [consent, setConsent] = useState(false);
  const [valid, setValid] = useState(false);

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const subscribe = async () => {
    try {
      setLoading(true);

      if (!emailAddress) {
        throw new Error('No email address provided');
      }

      if (!consent) {
        throw new Error('Consent not provided');
      }

      if (!validateEmail(emailAddress)) {
        throw new Error('Invalid email address');
      }

      const res = await axios.put('/api/signup', { mail: emailAddress });

      if (res.status === 200) {
        toast.success(res.data.message);
        setEmailAddress('');
        setLoading(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === 'No email address provided') {
          return toast.error("Oops, you didn't enter an email address!");
        }

        if (error.message === 'Consent not provided') {
          return toast.error("Oops, you didn't check the consent checkbox!");
        }

        if (error.message === 'Invalid email address') {
          return toast.error("Oops, you didn't enter a valid email address!");
        }
      }

      return toast.error(
        'Oops, there was a problem with your subscription, please try again or contact us'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValid(consent && !!validateEmail(emailAddress));
  }, [consent, emailAddress]);

  return (
    <>
      <form
        className="my-10 md:flex justify-center items-center"
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
          type="email"
          placeholder="Email address*"
          className="input input-primary input-bordered max-md:mb-5"
          value={emailAddress}
        ></input>
        <button
          onClick={subscribe}
          className={`button button-dark button-small btn ml-3 ${
            loading ? 'btn-disabled loading' : 'btn-primary'
          } disabled:opacity-10`}
          disabled={!valid}
        >
          {loading ? (
            <div role="status">
              <svg
                aria-hidden="true"
                className="m-auto w-6 h-6 animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            'Sign up'
          )}
        </button>
      </form>
      <div className="md:flex md:space-x-10 max-md:space-y-10">
        <div className="flex-1 text-lg leading-[30px]">
          <div className="flex items-center mb-4 align-start">
            <div className="flex items-start mr-4 mb-2">
              <input
                type="checkbox"
                id="consent"
                className="opacity-0 absolute h-6 w-6"
                onChange={(event) => {
                  setConsent(event.target.checked);
                }}
              />
              <div className="bg-transparent border-2 rounded-md border-black w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-black">
                <svg
                  className="fill-current hidden w-3 h-3 text-black pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="#000"
                      fillRule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <label htmlFor="consent" className="select-none">
                <p className="text-xs mb-6">
                  By clicking to sign up you provide your consent to sign up to
                  Facture.art's newsletter and will be the first to know of new
                  features, artists and pricing options on Facture. For more
                  information about how we use your personal data, please see
                  our{' '}
                  <Link
                    href="/privacy-policy"
                    title="Privacy Policy"
                    className="underline"
                  >
                    Privacy Policy
                  </Link>
                  .
                </p>
              </label>
            </div>
          </div>

          <p className="text-xs">
            You can unsubscribe from our newsletters at any time by clicking the
            "unsubscribe" option at the bottom of any of our newsletters.
          </p>
        </div>
      </div>
    </>
  );
};
