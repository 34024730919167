import Image from 'next/image';
import Link from 'next/link';

export default () => {
  return (
    <section
      className="bg-cover bg-center bg-no-repeat overflow-hidden text-white"
      style={{ backgroundColor: '#262628' }}
    >
      <div className="container mx-auto px-10 py-20">
        <div className="md:grid grid-cols-4 gap-10">
          <div className="col-span-2">
            <div className="flex-1 mb-20 text-[18px] leading-[30px]">
              <Link href="/" title="Facture">
                <Image
                  src="/images/facture-logo-white.svg"
                  alt="Facture Logo"
                  width="130"
                  height="28"
                  className="mb-10"
                />
              </Link>
              <p className="mb-6">
                Accelerating the transition towards online, aggregated, and
                always up-to-date catalogues raisonnés.
              </p>
              <div className="text-[16px] max-md:hidden">
                <p>All artworks &copy; the artists.</p>
                <p>
                  &copy; {new Date().getFullYear()} All Rights Reserved.
                  Artwishlist Ltd.
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-1 mt-16">
            <div className="mb-10">
              <h3 className="mb-2 text-[#919191] uppercase">Menu</h3>
              <ul className="text-sm space-y-1.5">
                <li>
                  <Link
                    href="/#artwork-timeline"
                    scroll={false}
                    className="hover:underline"
                  >
                    Artwork Timeline
                  </Link>
                </li>
                <li>
                  <Link
                    href="/#search"
                    scroll={false}
                    className="hover:underline"
                  >
                    Search
                  </Link>
                </li>
                <li>
                  <Link
                    href="/#image-recognition"
                    scroll={false}
                    className="hover:underline"
                  >
                    Image recognition
                  </Link>
                </li>
                <li>
                  <Link
                    href="/#editing"
                    scroll={false}
                    className="hover:underline"
                  >
                    Editing
                  </Link>
                </li>
                <li>
                  <a
                    href="https://search.facture.art/"
                    className="hover:underline"
                  >
                    Sign up
                  </a>
                </li>
              </ul>
              <h3 className="mt-8 mb-2 text-[#919191] uppercase">
                Information
              </h3>
              <ul className="text-sm space-y-1.5">
                <li>
                  <Link
                    href="/terms-and-conditions"
                    className="hover:underline"
                  >
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link href="/privacy-policy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-1 mt-16">
            <div className="mb-10">
              <h3 className="mb-2 text-[#919191] uppercase">Contact us</h3>
              <ul className="text-sm">
                <li>
                  <a
                    href="mailto:contact@facture.art"
                    className="hover:underline"
                  >
                    contact@facture.art
                  </a>
                </li>
              </ul>
            </div>
            <div className="mb-10">
              <h3 className="mb-2 text-[#919191] uppercase">Follow us</h3>
              <ul className="text-sm space-y-1.5">
                <li>
                  <a
                    href="https://www.instagram.com/facture.art"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
            <div className="mb-10">
              <h3 className="mb-2 text-[#919191] uppercase">Try us</h3>
              <ul className="text-sm space-y-1.5">
                <li>
                  <a
                    href="https://wa.me/447787067215"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    WhatsApp
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/facture_art_bot"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    Telegram
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="text-[16px] md:hidden mt-20">
            <p>All artworks &copy; the artists.</p>
            <p>
              &copy; {new Date().getFullYear()} All Rights Reserved. Artwishlist
              Ltd.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
