export default () => {
  return (
    <section
      className="bg-cover bg-center bg-no-repeat overflow-hidden text-center"
      style={{
        backgroundColor: '#fff52c',
      }}
    >
      <div className="container mx-auto px-10 py-20 max-w-xl">
        <h2 className="text-4xl font-normal mb-6">Are you a business?</h2>
        <div className="md:flex md:space-x-10 max-md:space-y-10">
          <div className="flex-1 text-lg leading-[30px]">
            <p className="mb-6">
              If you are a business and would like to find out about our bespoke
              solutions or dedicated API access please{' '}
              <a
                href="mailto:christian@facture.art?subject=Facture business solution enquiry"
                className="underline"
              >
                get in touch
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
