import Image from 'next/image';
import Link from 'next/link';

interface MenuProps {
  onMenuOpen: () => void;
  open: boolean;
}

export default ({ onMenuOpen, open }: MenuProps) => {
  return (
    <>
      <div
        className={`fixed top-0 left-0 min-h-full min-w-full ${
          !open ? 'hidden' : ''
        }`}
        style={{ background: 'rgba(0,0,0,0.5)' }}
        onClick={() => onMenuOpen()}
      />
      <menu
        className="flex fixed top-0 right-0 h-full text-white transition-transform duration-500"
        style={{
          backgroundColor: '#262628',
          width: 340,
          transform: `translateX(${open ? '0' : '100%'})`,
        }}
      >
        <div className="flex flex-1 flex-col justify-between container mx-auto px-10 py-10 overflow-y-scroll">
          <div>
            <Image
              src="/images/close.svg"
              alt="Facture"
              width="30"
              height="30"
              className="absolute right-5 cursor-pointer"
              onClick={() => onMenuOpen()}
            />
            <Link href="/" onClick={() => onMenuOpen()}>
              <Image
                src="/images/facture-logo.svg"
                alt="Facture"
                width="453"
                height="94"
                style={{ width: '50%' }}
              />
            </Link>
            <ul className="my-10">
              <li className="mb-6 text-2xl">
                <Link
                  href="/#artwork-timeline"
                  onClick={() => onMenuOpen()}
                  scroll={false}
                >
                  Artwork Timeline
                </Link>
              </li>
              <li className="mb-6 text-2xl" onClick={() => onMenuOpen()}>
                <Link href="/#search" scroll={false}>
                  Search
                </Link>
              </li>
              <li className="mb-6 text-2xl" onClick={() => onMenuOpen()}>
                <Link href="/#image-recognition" scroll={false}>
                  Image recognition
                </Link>
              </li>
              <li className="mb-6 text-2xl" onClick={() => onMenuOpen()}>
                <Link href="/#editing" scroll={false}>
                  Editing
                </Link>
              </li>
              <li className="mb-6 text-2xl" onClick={() => onMenuOpen()}>
                <a href="mailto:contact@facture.art">Contact</a>
              </li>
            </ul>
          </div>
          <div>
            <a href="https://api.facture.art/login" className="button">
              Log in
            </a>
          </div>
        </div>
      </menu>
    </>
  );
};
