import Image from 'next/image';
import { assetPath } from '../constants';

export default () => {
  return (
    <section
      className="bg-cover bg-center bg-no-repeat overflow-hidden text-white"
      id="image-recognition"
      style={{ backgroundColor: '#3d3d3f' }}
    >
      <div className="container mx-auto px-10 py-20">
        <h2 className="font-medium uppercase text-[15px] leading-[23px] md:text-[18px] md:leading-[26px] lg:text-[20px] lg:leading-[31px] mb-6">
          3. Image recognition
        </h2>
        <div className="md:flex md:space-x-10 max-md:space-y-10">
          <div className="flex-1">
            <h3 className="font-normal text-[27px] leading-[31px] md:text-[30px] md:leading-[35px] lg:text-[40px] lg:leading-[46px]">
              In a visual world you need visual search.
            </h3>
          </div>
          <div className="flex-1 text-lg leading-[30px]">
            <p className="mb-6">
              In an increasingly globalised world of different cultures and
              identities visual language becomes as important for communication
              as the written word.
            </p>
            <p className="mb-6">
              Search any artwork with an illustration of that work by taking a
              photo or uploading a JPEG to receive the artwork details within
              seconds.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="container mx-auto px-10 pb-20">
          <Image
            src="/images/image-recognition.png"
            width="990"
            height="2412"
            alt=""
            className="md:hidden"
          />
          <video autoPlay playsInline loop muted className="max-md:hidden">
            <source
              src={`${assetPath}/video/image-recognition.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
};
