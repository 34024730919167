import Image from 'next/image';

interface HeroSectionProps {
  onMenuOpen: () => void;
}

export default ({ onMenuOpen }: HeroSectionProps) => {
  return (
    <section className="hero md:flex">
      <div className="container mx-auto px-10 md:flex md:flex-1">
        <div className="flex flex-1 justify-between pt-10 lg:py-20">
          <div className="flex flex-col justify-between flex-1">
            <Image
              src="/images/facture-logo.svg"
              alt="Facture"
              width="453"
              height="94"
              style={{ width: '80%', maxWidth: 453 }}
              className="mb-5"
            />
            <div className="max-md:hidden">
              <h1 className="text-5xl mb-10 text-[#fff52c]">
                Setting the new standard for art research.
              </h1>
              <a
                href="https://search.facture.art/"
                className="button button-sm mb-10"
              >
                Sign Up
              </a>
            </div>
          </div>
          <div className="flex items-start justify-end flex-1">
            <Image
              src="/images/burger.svg"
              alt="Facture"
              width="55"
              height="22"
              onClick={() => onMenuOpen()}
              className="pt-1 md:pt-3 max-md:max-w-[40px] cursor-pointer"
            />
          </div>
        </div>
        <div className="md:hidden">
          <h1 className="text-[20px] leading-[25px] md:text-5xl mb-5 md:mb-10 text-[#fff52c]">
            Setting the new standard for art research.
          </h1>
          <a
            href="https://search.facture.art/"
            className="button button-sm mb-10"
          >
            Sign Up
          </a>
        </div>
      </div>
    </section>
  );
};
