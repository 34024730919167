import Image from 'next/image';

export default () => {
  return (
    <section
      className="flex overflow-hidden text-white"
      id="editing"
      style={{ backgroundColor: '#8d7455' }}
    >
      <div className="mx-auto">
        <div className="lg:flex lg:space-x-10 max-lg:space-y-10">
          <div className="flex-1">
            <Image
              src="/images/catalogue-mobile.png"
              width="1369"
              height="1480"
              alt=""
              className="lg:hidden"
              style={{ marginBottom: '-30vw' }}
            />
            <Image
              src="/images/catalogue-desktop.png"
              width="1369"
              height="2192"
              alt=""
              className="max-lg:hidden"
            />
          </div>
          <div className="flex-1">
            <div className="mt-20 pr-24 max-lg:px-10">
              <h2 className="font-medium uppercase text-[15px] leading-[23px] md:text-[18px] md:leading-[26px] lg:text-[20px] lg:leading-[31px] mb-6">
                4. Editing
              </h2>
              <h3 className="font-normal text-[27px] leading-[31px] md:text-[30px] md:leading-[35px] lg:text-[40px] lg:leading-[46px] mb-6">
                Take back control of art history authority.
              </h3>
              <div className="flex-1 text-lg leading-[30px]">
                <p className="mb-6">
                  Supplement catalogue raisonné data by adding a note, or view
                  the comments and corrections submitted by a network of
                  experts.
                </p>
                <p className="mb-6">
                  On Facture, you can interact with others and improve the
                  accuracy of a specific catalogue raisonné entry, just by
                  submitting a comment.
                </p>
              </div>
            </div>
            <div>
              <Image
                src="/images/comments.png"
                width="1512"
                height="1610"
                alt=""
                className="mt-20"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
